import React from 'react'
import styled from 'styled-components'
import Image from 'components/Image'

// TODO: connect CMS
import _logo from 'images/DealerCampaignseite/normstahl_en.png'

// ===================================================
// STYLES
// ===================================================

const Navbar = styled('div')({
  background: ({ theme }) => theme.colors.gray.color,
  width: '100%',
})

const NavbarContent = styled('div')({
  padding: '16px 24px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: 1600,
  margin: 'auto',
})

const LogoContainer = styled('div')({
  position: 'relative',
  width: 180,
})

const Logo = styled(Image)({})

const LinksContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flex: 1,
})

const Link = styled('a')({
  display: 'flex',
  alignItems: 'center',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  margin: '0 12px',
  transition: 'color 225ms',
  color: ({ theme }) => theme.colors.darkGray.color,
  '&:last-child': {
    marginRight: 0,
  },
  '&:hover': {
    color: 'black', // TODO: why is theme.colors.black.color not black?
  },
})

// ===================================================
// COMPONENTS
// ===================================================

const NavLink = ({ label, anchor = '#anfrage' }) => (
  <Link href={anchor}>{label}</Link>
)

const StoryblokNavbar = ({ data: { logo = _logo, links = [] } }) => (
  <Navbar>
    <NavbarContent>
      <LogoContainer>
        <Logo critical src={logo?.filename} alt={logo?.alt} />
      </LogoContainer>
      <LinksContainer>
        {links.map(link => (
          <NavLink {...link} key={`nav-link-${link?.label}`} />
        ))}
      </LinksContainer>
    </NavbarContent>
  </Navbar>
)

export default StoryblokNavbar
